import { Box, Typography } from "@mui/material";
import * as React from 'react';

export function AboutScreen() {
    return (
      <Box 
        sx={{
          left: { xs: '', md: '50%', lg: '50%', xl: '50%' },
          position: 'relative',
          transform: { xs: '', md: 'translateX(-50%)', lg: 'translateX(-50%)', xl: 'translateX(-50%)'},
          width: { xs: '100%', md: '60%', xl: '480px', lg: '480px' },
      }}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

            <img src={process.env.PUBLIC_URL + "/CraftingOmmaWheel.jpg"} alt="KayAmesPhoto" width={"100%"}/>

            <Typography variant="body2" color="text.secondary">
              <p><span>Thank you for visiting my small world of creations.</span></p>
              
              <p><span>I love anything that is handmade.  Creating something unique and special has always been my passion since I was a little girl in South Korea, whether it was from knitting, quilting, paper folding or stitching. I believe the value of time, energy and love that goes into handmade items is priceless. I found my love for clay in 2016 when I began lessons at Currier Arts in Manchester, NH. I have been striving to find my way since. I love exploring new techniques and ideas, and enjoy learning something new. Each piece here has been uniquely hand crafted, inspired by nature and daily life. My hope is to bring joy into this world one piece at a time.</span></p>
            </Typography>    
            <img src={process.env.PUBLIC_URL + "/CraftingOmmaCarve.png"} alt="KayAmesPhoto" width={"100%"}/>

          </div>
      </Box>
    )
}

export default AboutScreen;
